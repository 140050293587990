$(document).ready(function () {
  let slideIndex = 0;
  const slides = $('.popup-slide');
  const dots = $('.indicator__step');
  const nextButton = $('.indicator__button.next');
  const prevButton = $('.indicator__button.prev');

  showSlides(slideIndex);

  nextButton.click(function () {
    if (slideIndex < slides.length - 1) {
      slideIndex = slideIndex + 1;
    }

    showSlides(slideIndex);
  });

  prevButton.click(function () {
    if (0 < slideIndex) {
      slideIndex = slideIndex - 1;
    }

    showSlides(slideIndex);
  });

  $('.indicator__step').click(function () {
    slideIndex = $(this).index();
    showSlides(slideIndex);
  });

  function showSlides(index) {
    let i;

    // Hide all slides
    for (i = 0; i < slides.length; i++) {
      $(slides[i]).css('display', 'none');
    }

    // Hide all buttons
    $('.indicator__button').css('visibility', 'hidden');

    // Remove active dots
    for (i = 0; i < dots.length; i++) {
      $(dots[i]).removeClass('active');
    }

    // Show prev button only when page is not first one
    if (0 < slideIndex) {
      prevButton.css('visibility', 'visible');
    } else {
      prevButton.css('visibility', 'hidden');
    }

    // Show next button only when page is not last one
    if (slideIndex < slides.length - 1) {
      nextButton.css('visibility', 'visible');
    } else {
      nextButton.css('visibility', 'hidden');
    }

    // Show active page
    $(slides[index]).css('display', 'block');
    $(dots[index]).addClass('active');
  }
});
