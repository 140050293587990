jQuery(document).ready(function ($) {

    if (0 === $('.purchase-content').length) {
        return;
    }

    const ga = window.ga;
    const stepTemplate = $('#step-template').html();
    const hubspotFormId = '#hubspot-purchase-form';
    const selectionPrefix = 's_';
    const $startElement = $('[data-step-key]').first();
    const startKey = $startElement.attr('data-step-key');
    let stepsArr = [];
    let allStepCombinations = {};

    function findKeys($element) {
        const node = {};

        $element.find('[data-next-step]').each(function () {
            const key = $(this).attr('data-next-step');

            $(`[data-step-key='${key}']`).each(function () {
                node[key] = findKeys($(this));
            });
        });

        return 0 < Object.entries(node).length ? node : null;
    }

    const stepCombinations = (object) => {
        if (null === object) {
            return null;
        }

        const stack = [];

        Object.keys(object).forEach((key) => {
            const result = stepCombinations(object[key]);

            if (null === result) {
                stack.push([key]);
            } else {
                result.forEach((val) => {
                    stack.push([key, ...val]);
                });
            }
        });

        return stack;
    };

    const findChildKeys = (object, parentKey) => {
        const childKeys = Object.keys(object).map((key) => {
            if (null === object[key]) {
                return key;
            }

            return findChildKeys(object[key], key);
        });

        return childKeys.map((entry) => [parentKey, ...entry]);
    };

    function calculateNextStepCount() {
        const remainingCombinations = allStepCombinations.filter((entry) => {
            if (entry.length < stepsArr.length) {
                return false;
            }

            return stepsArr.every((step, index) => entry[index] === step);
        });

        return remainingCombinations
            .map((entry) => entry.length)
            .sort()
            .shift();
    }

    function onStepNumClick() {
        const stepNum = $(this).attr('data-step-num');

        if (stepsArr.length > stepNum) {
            processNextStep(stepsArr[stepNum - 1]);
        }
    }

    function adjustSteps() {
        const nextStepCount = calculateNextStepCount();
        const currentStepCount = $('.steps > div').length;
        const $steps = $('.steps');

        if (nextStepCount > currentStepCount) {
            for (let i = currentStepCount + 1; i <= nextStepCount; i++) {
                const $el = $(stepTemplate);

                $el.addClass(`step-${i}`);
                $el.text($el.text().replace('__num__', i));
                $el.attr('data-step-num', i);
                $steps.append($el);
            }
        } else if (nextStepCount < currentStepCount) {
            for (let i = currentStepCount; i > nextStepCount; i--) {
                $steps.find(`.step-${i}`).remove();
            }
        }

        $('.steps > div[data-step-num]').click(onStepNumClick);
    }
    function toogleContactFormData(select_key) {
        if (select_key == 'product_category_moq') {
            $('.s_product_category_moq').show();
            $('.default_contact_header').hide();

            $('.moq_comment_title').show();
            $('.default_comment_title').hide();
            $('#form_contact_material .slider-container').hide();
        } else {
            $('.s_product_category_moq').hide();
            $('.default_contact_header').show();

            $('.moq_comment_title').hide();
            $('.default_comment_title').show();
            $('#form_contact_material .slider-container').show();
        }
    }

    function onDataTypeCheckboxClick() {
        const select_key = $(this).parent().data('select-key');
        const value = 'true' === $(this).attr('data-checked');
        const newValue = !value;

        $(this).attr('data-checked', newValue);
        const selections_opt = getSelectedOptions(stepsArr);
        if ((select_key != 'colors' && select_key != 'production_technology')) {
            $(this).attr('data-requiredchecked', newValue);
        }
        toogleContactFormData(select_key);
        const $stepContent = $(this).parents('.step-content');
        const $button = $stepContent.find('button.continue');
        if (0 < $button.length) {
            if (newValue && $button.attr('disabled') && (select_key != 'colors' && select_key != 'production_technology')) {
                $button.removeAttr('disabled');
            } else if (!newValue && !$button.attr('disabled') && 0 === $stepContent.find('[data-requiredchecked="true"]').length) {
                $button.attr('disabled', 'disabled');
            }
        }

        reflectSelectionsToURL();
    }

    function onDataTypeNextStepClick() {
        $(this).parent().find('[data-type="next_step"]').attr('data-checked', false);
        $(this).attr('data-checked', true);

        onNextStepElementClicked.call(this);
    }

    function onNextStepElementClicked() {
        const url = $(this).attr('data-url');

        if (url) {
            window.open(url, $(this).attr('data-url-target') || '_blank');
        } else {
            const nextKey = $(this).attr('data-next-step');
            processNextStep(nextKey);
        }
    }

    function getFormData($form) {
        const data = {};

        $form.serializeArray().map(function (item) {
            if (data[item.name]) {
                if ('string' === typeof data[item.name]) {
                    data[item.name] = [data[item.name]];
                }

                data[item.name].push(item.value);
            } else {
                data[item.name] = item.value;
            }
        });

        return data;
    }

    function getSelectedOptions(selectedSteps, selectedOptionSelector = '[data-checked="true"]') {
        const mergeObjects = (obj1, obj2) => ({...obj1, ...obj2});

        const selections = selectedSteps
            .map((step) => {
                return $(`[data-step-key=${step}]`)
                    .map(function () {
                        return $(this)
                            .find('[data-select-key]')
                            .map(function () {
                                const $selection = $(this);
                                const selectKey = $selection.attr('data-select-key');
                                const selectedOptions = $selection
                                    .find(selectedOptionSelector)
                                    .map(function () {
                                        const $checkbox = $(this);

                                        return $checkbox.attr('data-key');
                                    })
                                    .get();

                                return {[selectKey]: selectedOptions};
                            })
                            .get()
                            .reduce(mergeObjects, {});
                    })
                    .get()
                    .reduce(mergeObjects, {});
            })
            .reduce(mergeObjects, {});

        return selections;
    }

    function getStepData($form) {
        const data = getFormData($form);
        const selections = getSelectedOptions(stepsArr);

        return {data, selections};
    }

    function toggleSteps(nextStepKey) {
        const currentStepKey = $('[data-step-key]:visible').attr('data-step-key');

        if (currentStepKey !== nextStepKey) {
            $(`[data-step-key='${currentStepKey}'], [data-step-key='${nextStepKey}']`).slideToggle(250);
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    function setActiveStep() {
        const percentage = (stepsArr.length / calculateNextStepCount()) * 100;

        $('.steps > div.active').removeClass('active');
        $(`.steps > div.step-${stepsArr.length}`).addClass('active');
        $('.step-progress-bar > div').css({width: `${percentage}%`});
        $('.steps > div[data-step-num]').each(function (index) {
            if (index < stepsArr.length) {
                $(this).removeClass('hidden-text');
            } else {
                $(this).addClass('hidden-text');
            }
        });
    }

    function reflectSelectionsToURL() {

        const selections = getSelectedOptions(stepsArr);
        const url = new URL(window.location);

        url.searchParams.forEach((value, key) => {
            if (key.indexOf(selectionPrefix) === 0) {
                url.searchParams.delete(key);
            }
        });

        url.searchParams.set('steps', stepsArr);

        Object.keys(selections).forEach(key => {
            if (selections[key].length > 0) {
                url.searchParams.set(`${selectionPrefix}${key}`, selections[key]);
            }
        });

        window.history.pushState({}, '', url);
    }

    function clearFollowingSteps() {
        $('[data-step-key]').each(function () {
            if (stepsArr.indexOf($(this).attr('data-step-key')) === -1) {
                $(this).find('[data-checked="true"]').attr('data-checked', false);
            }
        });
    }

    function processNextStep(nextStepKey, previousStepKey, omitUrlReflection) {
        const selections = getSelectedOptions(stepsArr);
        if ( selections['product_category_moq'] != undefined && selections['product_category_moq'].length > 0 && nextStepKey == 'contact_material' ) {
            toogleContactFormData('product_category_moq');
        } else {
            toogleContactFormData('other');
        }

        const stepIndex = stepsArr.indexOf(nextStepKey);

        if (-1 === stepIndex) {
            stepsArr.push(nextStepKey);
        } else {
            stepsArr.splice(stepIndex + 1);
        }

        clearFollowingSteps();

        if (!omitUrlReflection) {
            reflectSelectionsToURL();
        }

        adjustSteps();
        setActiveStep(nextStepKey);
        toggleSteps(nextStepKey);
    }

    $('button.back').click(function () {
        if (1 < stepsArr.length) {
            processNextStep(stepsArr[stepsArr.length - 2]);
        }
    });

    function displayThankYouPage(thankYouKey, email) {

        $('.steps-progress').hide();
        $(`[data-step-key='${thankYouKey}'] form input[type="email"]`).val(email);

        toggleSteps(thankYouKey);

        ga('send', {
            hitType: 'event',
            eventCategory: 'Forms',
            eventAction: 'Form-submit',
            eventLabel: 'Purchace'
        });
    }

    function clearStep(stepKey) {
        $(`[data-step-key="${stepKey}"]`).find('[data-checked="true"]').attr('data-checked', false);
    }

    function setSelectionsFromSearchParams(searchParams) {
        searchParams.forEach(function (value, key) {

            if (key.indexOf(selectionPrefix) === 0) {

                const dataSelectKey = key.substr(selectionPrefix.length);
                const dataKeys = value?.split(',');
                const $selectionOptions = $(`[data-select-key="${dataSelectKey}"]`);

                dataKeys.forEach(dataKey => $selectionOptions
                    .find(`[data-key="${dataKey}"]`)
                    .attr('data-checked', true)
                );
            }
        });
    }

    function enableStepContinueButton(step) {

        const $stepContainer = $(`[data-step-key="${step}"]`);

        if ($stepContainer.length) {

            const $button = $stepContainer.find('button.continue');

            if (0 < $button.length &&
                $button.attr('disabled') &&
                $stepContainer.find('[data-checked="true"]')) {

                $button.removeAttr('disabled');
            }
        }
    }

    function reflectUrlToSelections() {

        const url = new URL(window.location);
        const urlSteps = url.searchParams.get('steps')?.split(',');

        if (urlSteps && urlSteps.length > 1) {

            const previousStep = stepsArr?.length > 0 ? stepsArr[stepsArr.length - 1] : startKey;
            stepsArr = urlSteps.filter(value => allStepCombinations.find(steps => steps.indexOf(value) !== -1));

            stepsArr.forEach(clearStep);
            setSelectionsFromSearchParams(url.searchParams);
            stepsArr.forEach(enableStepContinueButton);

            processNextStep(stepsArr[stepsArr.length - 1], previousStep, true);

        } else if (stepsArr.length > 0) {

            stepsArr.forEach(clearStep);
            processNextStep(startKey, undefined, true);

        } else {

            stepsArr = [startKey];
            adjustSteps();
            setActiveStep();
        }
    }

    window.onpopstate = function (event) {
        reflectUrlToSelections();
    };

    function prepareForm() {
        let afterSubmitKey = '';
        let contactEmail = '';
        const $startElement = $('[data-step-key]').first();
        const startKey = $startElement.attr('data-step-key');

        const graph = {[startKey]: findKeys($startElement)};
        allStepCombinations = stepCombinations(graph);
        reflectUrlToSelections();

        function submitToHubspot(sourceFormData, thankYouKey) {

            if (!hubspotMapping) {
                console.warn('No hubspot mapping available!');

                return;
            }

            const {formData, selectOptions} = hubspotMapping;

            if (!formData) {
                console.warn('No hubspot mapping available for form data!');

                return;
            }

            if (!selectOptions) {
                console.warn('No hubspot mapping available for select options!');

                return;
            }

            const $targetForm = $(`${hubspotFormId} form`);
            afterSubmitKey = thankYouKey;
            contactEmail = sourceFormData.data.email;
            const {data, selections} = sourceFormData;

            function clearFormValues(mapping) {

                Object.keys(mapping).forEach((key) => {
                    const targetName = mapping[key];
                    const $targetInput = $targetForm.find(`input[name="${targetName}"]`);

                    if (0 < $targetInput.length) {
                        const isCheckbox = 'checkbox' === $targetInput.attr('type');
                        isCheckbox ? setInputValue($targetInput, false)
                            : setInputValue($targetInput, ''); // email input does not change unless using attr
                    } else {
                        console.warn(`Hubspot form has no target field of ${targetName}`);
                    }
                });
            }

            function mapValues(mapping, sourceData, isArray = false) {

                clearFormValues(mapping);

                Object.keys(mapping).forEach((key) => {
                    const sourceValue = sourceData[key];

                    if (!sourceValue) {
                        return;
                    }

                    const targetName = mapping[key];
                    const $targetInput = $targetForm.find(`input[name="${targetName}"]`);

                    if (0 < $targetInput.length) {
                        const isCheckbox = 'checkbox' === $targetInput.attr('type');
                        const currentVal = $targetInput.val();
                        const nextVal = (currentVal ? `${currentVal}, ` : '') + (isArray ? sourceValue.join(', ') : sourceValue);

                        isCheckbox ? setInputValue($targetInput, sourceValue)
                            : setInputValue($targetInput, nextVal); // email input does not change unless using attr
                    } else {
                        console.warn(`Hubspot form has no target field of ${targetName}`);
                    }
                });
            }

            disconnectErrorObserver();

            mapValues(formData, data);
            mapValues(selectOptions, selections, true);

            setupErrorObserver();

            $targetForm.submit();
        }

        let observer;
        let validator = $('form.contact-form').validate({
            submitHandler: function (form) {
                return false;
            }
        });

        function setupErrorObserver() {

            disconnectErrorObserver();

            const target = document.querySelector(`${hubspotFormId} form`);
            let timer;
            observer = new MutationObserver(function(mutations) {
                timer && clearTimeout(timer);
                timer = setTimeout(() => reflectErrorsToVisibleForm(), 500);
            });

            const config = { attributes: true, childList: true, characterData: true, subtree: true};

            observer.observe(target, config);
        }

        function disconnectErrorObserver() {
            observer && observer.disconnect();
        }

        function reflectErrorsToVisibleForm() {

            validator.resetForm();

            const $targetForm = $(`${hubspotFormId} form`);
            const {formData, selectOptions} = hubspotMapping;
            const sourceInputNames = Object.keys(formData);
            const errors = {};

            $targetForm.find('label.hs-error-msg').each(function () {
                const $el = $(this);
                const error = $el.text();
                const targetInputName = $el.closest('.field').find('input').attr('name');
                const sourceInputName = sourceInputNames.find(name => formData[name] === targetInputName);
                errors[sourceInputName] = error;
            });

            if (Object.keys(errors).length > 0) {
                validator.showErrors(errors);
            }
        }

        function onContactFormSubmit() {
            if ($(this).valid()) {
                const formData = getStepData($(this));
                const thankYouKey = $(this).find('[type="submit"]').attr('data-thank-you-key');

                submitToHubspot(formData, thankYouKey);
            }

            return false;
        }

        function onSubscribeFormSubmit() {
            const $form = $(this);

            if ($form.valid()) {
                const formData = getFormData($form);
                const $parent = $form.closest('.subscribe-container');
                const $targetForm = $parent.find('.subscribe-form-hubspot form');

                $targetForm.find('input[name="email"]').val(formData.email);
                $targetForm.find('input[name="accept_newsletter"]').prop('checked', formData.policy_agreement);
                $targetForm.submit();
            }

            return false;
        }

        $('[data-type="next_step"]:not([disabled])').click(onDataTypeNextStepClick);

        $('button.continue').click(onNextStepElementClicked);

        $('[data-type="checkbox"]:not([disabled])').click(onDataTypeCheckboxClick);

        $('#form_contact_material, #form_contact_jars_for_food, #form_contact_jar, #form_contact_jewelry_or_hanger, #form_contact_straw_large').on('submit', onContactFormSubmit);

        $('form.subscribe-form').on('submit', onSubscribeFormSubmit);

        function formatWithSeparators(value) {
            if (1000 > value) {
                return value;
            }

            return value
                .toString()
                .split('')
                .reverse()
                .reduce((accu, val, idx) => `${val}${0 === idx % 3 && 3 <= idx ? '.' : ''}${accu}`, '');
        }

        $('.slider-amount').each(function () {
            const $range = $(this).find('[type=range]');
            const $display = $(this).find('.amount-display');

            $range.change(
                (e) => {
                    const {value, style} = e.target;

                    style.setProperty('--val', +value);
                    $display.val(formatWithSeparators(value)); // Format number to have thousand separator
                }
            );

            $display.val(formatWithSeparators($display.val())); // Format number to have thousand separator
        });

        $('select').change(function () {
            $(this).removeClass('placeholder');
        });

        hbspt.forms.create({
            portalId: '4595283',
            formId: '255cc595-552c-4d86-a066-903b2d82d09a',
            target: hubspotFormId,
            onFormReady: function ($form) {
                // console.log('Hubspot form fields');
                // console.log(
                //     JSON.stringify(
                //         $form
                //             .find('input:not([type="submit"])')
                //             .toArray()
                //             .map((el) => $(el).attr('name'))
                //     )
                // );
                //
                // console.log('Sulapac purchase form fields');
                const allFormFields = $('.purchase-content .step-content form')
                    .toArray()
                    .map((form) =>
                        $(form)
                            .find('input, select, textarea')
                            .toArray()
                            .map((el) => $(el).attr('name'))
                    )
                    .reduce((acc, val) => [...acc, ...val], []);
                // console.log(JSON.stringify([...new Set(allFormFields)]));
                // console.log('Sulapac purchase form options');
                const allSteps = $('.purchase-content [data-step-key]')
                    .toArray()
                    .map((el) => $(el).attr('data-step-key'));
                // console.log(JSON.stringify(getSelectedOptions(allSteps, '[data-key]')));
            },
            onFormSubmitted: function ($form) {
                displayThankYouPage(afterSubmitKey, contactEmail);
            },
        });

        $('.purchase-content .subscribe-form-hubspot').each(function () {
            hbspt.forms.create({
                portalId: '4595283',
                formId: 'eda33442-774d-4b8d-beb5-251c781aebde',
                target: '#' + $(this).attr('id'),
                inlineMessage: 'Bogus to keep from redirect',
                onFormSubmitted: function ($form) {
                    const $parent = $form.closest('.subscribe-container');

                    $parent.find('.subscribe-group, .thank-you-group').toggle();
                },
            });
        });
    }

    const setInputValue = ($inputElement, value) => {

        if ($inputElement.length === 0) {
            return;
        }

        const isCheckbox = 'checkbox' === $inputElement.attr('type');

        if (isCheckbox) {
            $inputElement
                .prop('checked', value);
        } else {
            $inputElement
                .val(value)
                .attr('value', value);
        }

        $inputElement.get(0).dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
    };

    prepareForm();
});
