import 'bootstrap-sass';
import './straw-popup';
import './purchase';
import './asset-order';
import { CookieStorage } from 'cookie-storage';


function navShrink() {
  var mouseY = 0;

  document.addEventListener('mousemove', function(e) {
    mouseY = e.clientY || e.pageY;
    if(mouseY < 80) {
       $('nav').css({ top: '0' });
    }
  }, false);

  $(window).scroll(function() {
    var sc = ($(this).scrollTop() > 80) ? $('nav').css({ top: '-80px' }) : $('nav').css({ top: '0' });
  });

  $('nav').mouseout(function() {
    if($(window).scrollTop() > 80) {
       $('nav').css({ top: '-80px' });
    }
  });
}

navShrink();

function getFormData($form) {
    const data = {};

    $form.serializeArray().map(function (item) {
        if (data[item.name]) {
            if ('string' === typeof data[item.name]) {
                data[item.name] = [data[item.name]];
            }

            data[item.name].push(item.value);
        } else {
            data[item.name] = item.value;
        }
    });

    return data;
}
//Change  Product image background for responsive in find products page
const $findproduct = $('.find-products-content .product-background'); 
$(window).on('load resize orientationchange', function() {
  $findproduct.each(function() {
    let desktop_url = $(this).data('desktop-img');
    let mobile_url = $(this).data('mobile-img');
    if ($(window).width() < 768) {
      if(!mobile_url) {
        mobile_url = desktop_url;
      }
      $(this).css("background-image", "url('"+ mobile_url +"')");
    } else {
     $(this).css("background-image", "url('"+ desktop_url +"')");
    }
  });
});

$(document).ready(function() {
  $( document ).on( "submit", ".subscribe-form", function( event ) {
    event.preventDefault();
    const $form = $(this);

    if ($form.valid()) {
        const formData = getFormData($form);
        const $parent = $form.closest('.subscribe-container');
        const $targetForm = $parent.find('.subscribe-form-hubspot form');

        $targetForm.find('input[name="email"]').val(formData.email);
        $targetForm.find('input[name="accept_newsletter"]').prop('checked', formData.policy_agreement);
        $targetForm.submit();
    }

    return false;
  });
  //Storytelling change dynamic background
  $(".back_ele:eq(0)").addClass('active');
  $('.header-background').on("scroll touchmove", function() 
  {
      let elmentheight = $('.scroll_element').innerHeight();
      let index = parseInt($(this).scrollTop() / (elmentheight*0.95));
      $(".back_ele").removeClass('active');
      $(".back_ele:eq("+ index +")").addClass('active');
      if($(".back_ele:eq("+ index +")").prop("nodeName") == 'VIDEO'){
        let videoid = $(".back_ele:eq("+ index +")").prop("id");
        document.getElementById(videoid).play();
      }
  });

  //Autoplay video in blog page
  window.addEventListener('load', videoScroll);
  window.addEventListener('scroll', videoScroll);

  function videoScroll() {

    if ( document.querySelectorAll('.content-video.autoplay video').length > 0) {
      let windowHeight = window.innerHeight,
      videoEl = document.querySelectorAll('.content-video.autoplay video');

      for (let i = 0; i < videoEl.length; i++) {

        let thisVideoEl = videoEl[i],
        videoHeight = thisVideoEl.clientHeight,
        videoClientRect = thisVideoEl.getBoundingClientRect().top;

        if ( videoClientRect <= ( (windowHeight) - (videoHeight*.5) ) && videoClientRect >= ( 0 - ( videoHeight*.5 ) ) ) {
          thisVideoEl.play();
        } else {
          thisVideoEl.pause();
        }

      }
    }

  }
  // Hide/Show search form in header
  $('.search-icon, .mobile_search_icon').on('click', function() {
    $(".search-bar").slideToggle(500);
    $("body").toggleClass('stop-scroll');
    $('#menu-button').click();
    $('div.navbar').toggleClass('open');
    $(".close_section").toggleClass('hideclose');
  });

  // Contact hubspot form thank you section
  const $contactform = $('#contact-hbspt-form');
  if ($contactform.length) {
    let email ='';
    hbspt.forms.create({
        region: "na1",
        portalId: "4595283",
        formId: "c093db06-ddbd-4e7c-9f2b-61f56ae1390e",
        target: '#contact-hbspt-form',
        onFormSubmit : function($form, ctx) {
          const formData = getFormData($form);
          email = formData.email;
          $(`.subscribe-form input[type="email"]`).val(email);
        },
        onFormSubmitted: function ($form) {
          window.location.href = "/thank-you-for-contact";
        },
    });
  }

  // utensils hubspot form thank you section
  const $utensilsform = $('#utensils-form');
  if ($utensilsform.length) {
    let email ='';
    hbspt.forms.create({
        region: "na1",
        portalId: "4595283",
        formId: "ca4bea6e-e710-4d1b-bd27-135fb158c93d",
        target: '#utensils-form',
        onFormSubmit : function($form, ctx) {
          const formData = getFormData($form);
          email = formData.email;
          $(`.subscribe-form input[type="email"]`).val(email);
        },
        onFormSubmitted: function ($form) {
            let htmlThankspage = $('#thanks_request_section').html();
            $('#main-content').html(htmlThankspage);
            $('#frontpage-header').hide();
            $(`.subscribe-form input[type="email"]`).val(email);
            $('html, body').animate(
              {
                scrollTop: $('html').offset().top
              },
              500
            );
        },
    });
  }
  // Manufacturing form
  const $manufacturingform = $('#manufacturing-form');
  if ($manufacturingform.length) {
    let email ='';
    hbspt.forms.create({
        region: "na1",
        portalId: "4595283",
        formId: "46ad0fac-f6da-4570-a811-961181a5ba32",
        target: '#manufacturing-form',
        onFormSubmit : function($form, ctx) {
          const formData = getFormData($form);
          email = formData.email;
          $(`.subscribe-form input[type="email"]`).val(email);
        },
        onFormSubmitted: function ($form) {
            let htmlThankspage = $('#thanks_request_section').html();
            $('#main-content').html(htmlThankspage);
            $('#frontpage-header').hide();
            $(`.subscribe-form input[type="email"]`).val(email);
            $('html, body').animate(
              {
                scrollTop: $('html').offset().top
              },
              500
            );
        },
    });
  }

  // Straw hubspot form thank you section
  const $strawform = $('#straw-form');
  if ($strawform.length) {
    let email ='';
    hbspt.forms.create({
        region: "na1",
        portalId: "4595283",
        formId: "b11569dd-d06f-473b-b511-69a3abb8a16c",
        target: '#straw-form',
        onFormSubmit : function($form, ctx) {
          const formData = getFormData($form);
          email = formData.email;
          $(`.subscribe-form input[type="email"]`).val(email);
        },
        onFormSubmitted: function ($form) {
            let htmlThankspage = $('#thanks_request_section').html();
            $('#main-content').html(htmlThankspage);
            $('#frontpage-header').hide();
            $(`.subscribe-form input[type="email"]`).val(email);
            $('html, body').animate(
              {
                scrollTop: $('html').offset().top
              },
              500
            );
        },
    });
  }
  const $thankscontent = $('.thanks-content-section .subscribe-form-hubspot');
  if ($thankscontent.length) {
    $('.thanks-content-section .subscribe-form-hubspot').each(function () {
        hbspt.forms.create({
            portalId: '4595283',
            formId: 'eda33442-774d-4b8d-beb5-251c781aebde',
            target: '#' + $(this).attr('id'),
            inlineMessage: 'Bogus to keep from redirect',
            onFormSubmitted: function ($form) {
                const $parent = $form.closest('.subscribe-container');
                jQuery('.subscribe-group').fadeToggle(400);
                jQuery('.thank-you-group').fadeToggle(400);
            },
        });
    });
  }

  const whiteBorderHubspotFormIds = [];

  window.addEventListener('message', event => {
    const {type, eventName, id} = event.data;
    if (type === 'hsFormCallback' && eventName === 'onFormReady') {
      // Add button and black-borders classes to hubspot forms
      const buttonStyle = whiteBorderHubspotFormIds.indexOf(id) !== -1 ? 'white' : 'black';
      $(`[data-form-id="${id}"]`).find('input[type="submit"]').addClass(`button ${buttonStyle}`);
    }
  });

  $('#catapultCookie').on('click', function() {
    $('##catapult-cookie-bar').addClass('hidden');
  });

  // Frontpage message button
  const $frontpageMessage = $('.frontpage-message');
  const cookieStorage = new CookieStorage();

  if (cookieStorage.getItem('seen-message') !== 'true') {
    $frontpageMessage.show();
    $frontpageMessage.find('button.close').click(function () {
      cookieStorage.setItem('seen-message', 'true');
      $frontpageMessage.hide();
    });
  }

  // MENU BUTTON
  $('#menu-button').click(function() {
    const $button = $(this);
    $button.toggleClass('open');
    $button.parent('div.navbar').toggleClass('open');

    if ($(this).hasClass('open')) {
      $('.extra-link.mobile').css('opacity', '1');
    } else {
      $('.extra-link.mobile').css('opacity', '0');
    }

    $('#menu').toggle('slow', function() {
      $(this).animate(
        {
          width: ['100%', 'swing'],
          height: ['100vh', 'swing']
        },
        {duration: 'slow', easing: 'easein'}
      );
    });
  });

  // MENU SCROLL

  $(document).on('scroll', navShrink);

  // MENU ACCORDION MOBILE
  const isNavCollapsed = 992 > $(window).width();

  $('.dropdown .menu-item').click(function() {
    if (isNavCollapsed) {
      $('#menu-button').click();
    }
  });

  // ANCHOR LINKS

  $('ul.dropdown-menu > li a').each(function() {
    var $this = $(this);
    var _href = $this.attr('href');
    var parentUrl = $this.closest('.dropdown').find('a').attr('href');

    if (/^#/.test(_href)) {
      $this.attr('href', parentUrl + _href);
    }
  });

  // FRONTPAGE PRODUCT HOVERS

  if (767 < $(window).width()) {

    $('.frontpage-content .products .highlight').hover(
      function() {
        $(this).find('.highlight-content').toggleClass('hovered');
        $(this).find('img').fadeToggle(500);
        $(this).find('p').delay(400).fadeIn();
      },
      function() {
        $(this).find('.highlight-content').delay(1600).toggleClass('hovered');
        $(this).find('p').fadeOut();
        $(this).find('img').delay(200).fadeToggle(500);
      }
    );
  }

  // VIDEO AUTOPLAY

  $('a.modal-button').click(function() {
    $('.videomodal video').get(0).play();
  });

  $('.video-container span[data-dismiss=\'modal\']').click(function() {
    $('.videomodal video').get(0).pause();
  });

  // ABOUT PAGE PERSON HOVERS

  if (767 < $(window).width()) {
    $('.about-content .people .person .image').hover(function() {
      $(this).find('.hover').fadeToggle(400);
    });
  } else {
    $('.about-content .people .person .image .show-hover').click(function() {
      $(this).siblings().fadeToggle(400);
    });
  }

  // MATCH HEIGHTS
  $('.frontpage-content .customers-awards .customers').matchHeight();
  $('.frontpage-content .customers-awards .awards').matchHeight();
  $('.blog-content .single-post-container').matchHeight();
  $('.products-content .features .feature').matchHeight();
  $('.media-content .media-content-image-block .media-image').matchHeight();
  $('.contact-images .images img').matchHeight();
  $('.contact-videos .videos video').matchHeight();
  // MODAL FORMS
  const $newsletter = $('.newsletter');

  if ($newsletter.length) {
    $newsletter.each(function() {
      const formId = $(this).data('data-form-id');
      hbspt.forms.create({
        portalId: '4595283',
        formId: formId || 'eda33442-774d-4b8d-beb5-251c781aebde',
        css: '',
        target: '.newsletter.form'
      });
    });
  }

  if ($('.pdfmodal-content').length) {
    const $downloadPdfModal = $('.download-pdf-modal');
    const contentSelector = '.pdfmodal-content';
    const $content = $(contentSelector);

    function clearAttributes() {
      $content.removeAttr('data-url');
      $content.removeAttr('data-target');
    }

    function isTriggeredByLinkButton() {
      return !!$content.attr('data-url');
    }

    function onFormSubmitted() {

      const $modalContent = $downloadPdfModal.find('.modal-content');
      let $originalMessage = $modalContent.find('.submitted-message-original');
      const $message = $modalContent.find('.submitted-message');

      if (isTriggeredByLinkButton()) {

        const $clone = $downloadPdfModal.find('.download-button-template').clone();
        $clone.attr('href', $content.attr('data-url'));
        $clone.attr('target', $content.attr('data-target'));
        $clone.attr('class', 'download-button');
        $clone.show();
        // Store the original message for the non link triggered scenarios to be shown
        if (!$originalMessage.length) {
          $originalMessage = $message.clone().attr('class', 'submitted-message-original').hide();
          $modalContent.append($originalMessage);
        }

        $message.html($clone);
        $message.show();

        clearAttributes();

      } else if($originalMessage.length) {

        $content.html($originalMessage.attr('class', 'submitted-message').show());
      }
    }

    hbspt.forms.create({
      portalId: '4595283',
      formId: '5b4c5334-049c-4f92-ab99-c039a9e4ebe4',
      target: contentSelector,
      onFormSubmit: function($form) {
        $('.modal-content .before-submit-messages').hide();
        $('.modal-content .success-messages').show();

        if (isTriggeredByLinkButton()) {
          $downloadPdfModal.find('.submitted-message').hide();
        }
        setTimeout(onFormSubmitted, 500);
      },  
    });

    $('.button').not('.download-button').click(function(event) {

      const $button = $(this);
      const href = $button.attr('href')

      if (/\.pdf$/i.test(href)) {

        event.preventDefault();
        $content.attr('data-url', href);
        $content.attr('data-target', $button.attr('target'));
        $downloadPdfModal.modal('show');

      } else {
        clearAttributes();
      }

      // If the form was already submitted then go ahead and trigger changing of the contents
      if($downloadPdfModal.find('.submitted-message').length) {
        onFormSubmitted();
      }
    })
  }

  if ($('.product-pdfmodal-content').length) {
    hbspt.forms.create({
      portalId: '4595283',
      formId: '5b4c5334-049c-4f92-ab99-c039a9e4ebe4',
      target: '.product-pdfmodal-content',
      onFormSubmit: function($form) {
        $('.modal-content .before-submit-messages').hide();
        $('.modal-content .success-messages').show();
      }
    });
  }

  // DOWNLOAD FORM FOR KEY FEATURE

  if ($('.key-feature-pdfmodal-content').length) {

    if ($('.microplastic-free').length) {
      // Microplastic free
      hbspt.forms.create({
        portalId: '4595283',
        formId: '15aa97da-d396-433c-8ea3-4f99e6fecdd2',
        target: '.key-feature-pdfmodal-content.microplastic-free',
        onFormSubmit: function($form) {
          $('.modal-content .before-submit-messages').hide();
          $('.modal-content .success-messages').show();
        }
      });
    }

    if ($('.compostable').length) {
      // Compostable
      hbspt.forms.create({
        portalId: '4595283',
        formId: 'd2d8816e-3aef-4b46-88ed-0e016c48a4d4',
        target: '.key-feature-pdfmodal-content.compostable',
        onFormSubmit: function($form) {
          $('.modal-content .before-submit-messages').hide();
          $('.modal-content .success-messages').show();
        }
      });
    }

    if ($('.raw-material').length) {
      // Sustainable raw material
      hbspt.forms.create({
        portalId: '4595283',
        formId: '6de4cf51-bd91-49cf-9317-4bb689fd86e9',
        target: '.key-feature-pdfmodal-content.raw-material',
        onFormSubmit: function($form) {
          $('.modal-content .before-submit-messages').hide();
          $('.modal-content .success-messages').show();
        }
      });
    }

    if ($('.mass-producible').length) {
      // Mass producible
      hbspt.forms.create({
        portalId: '4595283',
        formId: '694299ff-643f-4fd9-9a7a-01ad84b13e7e',
        target: '.key-feature-pdfmodal-content.mass-producible',
        onFormSubmit: function($form) {
          $('.modal-content .before-submit-messages').hide();
          $('.modal-content .success-messages').show();
        }
      });
    }

    if ($('.ocean-safe').length) {
      // Ocean safe
      hbspt.forms.create({
        portalId: '4595283',
        formId: 'ca813cb0-9461-44ff-8448-db0189e95e7e',
        target: '.key-feature-pdfmodal-content.ocean-safe',
        onFormSubmit: function($form) {
          $('.modal-content .before-submit-messages').hide();
          $('.modal-content .success-messages').show();
        }
      });
    }

    if ($('.safe-by-design').length) {
      // Safe by design
      hbspt.forms.create({
        portalId: '4595283',
        formId: '80badf36-bb9c-40ae-9013-e99d2eeb0e2e',
        target: '.key-feature-pdfmodal-content.safe-by-design',
        onFormSubmit: function($form) {
          $('.modal-content .before-submit-messages').hide();
          $('.modal-content .success-messages').show();
        }
      });
    }
  }

  $('.slick-slider-container').slick({
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false
  });

// COOKIES FIRST WORD WRAP

  $('#catapult-cookie-bar .ctcc-left-side').each(function() {
    var html = $(this).html();
    var word = html.substr(0, html.indexOf(' '));
    var rest = html.substr(html.indexOf(' '));
    $(this).html(rest).prepend($('<span/>').html(word).addClass('title'));
  });

// SLICK CAROUSELS
  let slidetoshow = 6;
  if($('.logo_cloud').length < 6){
    slidetoshow = $('.logo_cloud').length;
  }
  console.log(slidetoshow); 
  $('.slick-carousel.logo_cloud_carousel')
    .slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: slidetoshow,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            arrows: false
          }
        }
      ]
    });
  $('.cards.always-carousel').slick({
      dots: false,
      centerMode: false,
      infinite: false,
      speed: 200,
      fade: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      arrows: false,
      dotsClass: 'slick-dots'
  });

  $('.comparison-slider.single-product').on('init', function(event, slick) {
    var tapIndicator = $('input.tap-indicator').val();
    console.log('value: ' + tapIndicator);
    $('.comparison-slider.single-product .slick-next').text(tapIndicator);
  });

  $('.slick-carousel.frontpage').slick({
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }

      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('.slick-carousel.about-page')
    .slick({
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
            arrows: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }

        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    })
    .on('setPosition', function(event, slick) {
      $('.slick-carousel.about-page').each(function() {
        slick.$slideTrack.css('height', slick.$slideTrack.height() + 'px');
        $(this)
          .find('.about-page .slick-cloned')
          .css('height', slick.$slideTrack.height() + 'px');
      });
    });

  const $mobileOnlySlider = $('.mobile-only-slider');
  const $desktopOnlySlider = $('.desktop-only-slider');

  /* Slick needs no get Reinitialized on window Resize after it was destroyed */
  $(window).on('load resize orientationchange', function() {

    $desktopOnlySlider.each(function() {

      const $carousel = $(this);

      if ($(window).width() < 768) {
        if ($carousel.hasClass('slick-initialized')) {
          $carousel.slick('unslick');
        }
      } else {
        if ($carousel.hasClass('hero-carousel') && !$carousel.hasClass('slick-initialized')) {
          $carousel.slick({
            dots: true,
            infinite: true,
            speed: 2000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
            autoplaySpeed: 10000
          });
        }
      }
    });

    $mobileOnlySlider.each(function() {
      const $carousel = $(this);
      /* Initializes a slick carousel only on mobile screens */
      // slick on mobile
      const widths = [window.innerWidth];

      if (window.screen?.width) {
        widths.push(window.screen?.width);
      }

      const width = Math.min(...widths);

      if (width >= 768) {
        if ($carousel.hasClass('slick-initialized')) {
          $carousel.slick('unslick');
        }
      } else {
        if ($carousel.hasClass('highlights-nav') && !$carousel.hasClass('slick-initialized')) {
          $carousel.slick({
            dots: true,
            centerMode: true,
            infinite: true,
            speed: 200,
            fade: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            // autoplay: false,
            variableWidth: true,
            arrows: true,
            asNavFor: '.highlights-content',
            dotsClass: 'slick-dots top',
            prevArrow: '<div class=\'prev slick-prev\'></div>',
            nextArrow: '<div class=\'next slick-next\'></div>'
          });
        }
        if ($carousel.hasClass('highlights-content') && !$carousel.hasClass('slick-initialized')) {
          $carousel.slick({
            speed: 200,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            // autoplay: false,
            variableWidth: false,
            arrows: false,
            asNavFor: '.highlights-nav'
          });
        }
        if (($carousel.hasClass('cards') || $carousel.hasClass('posts')) && !$carousel.hasClass('slick-initialized')) {
          $carousel.slick({
            dots: true,
            centerMode: true,
            infinite: false,
            speed: 200,
            fade: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            dotsClass: 'slick-dots'
          });
        }
        if ($carousel.hasClass('hero-carousel') && !$carousel.hasClass('slick-initialized')) {
          $carousel.slick({
            dots: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
            autoplaySpeed: 6000
          });
        }
      }
    });
  });

  $('.timeline-slider').on('init', function() {
    var years = [];
    $('.timeline-slider .slick-slide:not(.slick-cloned) input').each(function() {
      years.push(this.value);
    });
    $('.timeline-slider ul.slick-dots li').each(function(index) {
      $(this).append('<span class="year">' + years[index] + '</span>');
    });
  });

  $('.timeline-slider')
    .slick({
      centerMode: true,
      slidesToShow: 1,
      dots: true,
      variableWidth: true,
      arrows: false,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            variableWidth: false
          }
        }
      ]
    })
    .on('setPosition', function(event, slick) {
      slick.$slides.css('height', slick.$slideTrack.height() + 'px');
      $('.timeline-slider .slick-cloned').css('height', slick.$slideTrack.height() + 'px');
    })
    .on('afterChange', function(event, slick) {
      $('.timeline .swipe-indicator-wrapper').fadeOut(1000);
    });

  $('.product .comparison-slider').slick({
    dots: true,
    infinite: true,
    speed: 200,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    prevArrow: '<div class=\'prev slick-prev\'></div>',
    nextArrow: '<div class=\'next slick-next\'></div>'
  });

  $('.portfolio #sulapac-comparison, .portfolio #sulapac-comparison-mobile').slick({
    dots: true,
    infinite: false,
    speed: 200,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    prevArrow: '<div class=\'prev slick-prev\'></div>',
    nextArrow: '<div class=\'next slick-next\'></div>'
  });

  $('.portfolio #other-comparison').slick({
    dots: true,
    infinite: false,
    speed: 200,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    prevArrow: '<div class=\'prev slick-prev\'></div>',
    nextArrow: '<div class=\'next slick-next\'></div>'
  });

// PRODUCTS FEATURES HIDE / SHOW

  function toggleShowHide($parent) {
    const show = $parent.find('.show-text');
    const hide = $parent.find('.hide-text');
    show.addClass('hide-text').removeClass('show-text');
    hide.addClass('show-text').removeClass('hide-text');
  }

  $('.less-more-button .button').click(function() {
    $(this).toggleClass('open');
    toggleShowHide($(this).parent())
    if (551 < $(window).width() && 991 > $(window).width()) {
      $('.third').slideToggle(500);
    }
    $('.hide-this').slideToggle(500);
  });

  // ABOUT PAGE FEATURES HIDE / SHOW
  $('.collapse-expense-button .button').click(function() {
    $(this).toggleClass('open');
    toggleShowHide($(this).parent())
    $('.hide-this').slideToggle(500);
  });


  // COMPOSTABILITY ACCORDION HIDE / SHOW
  $('.accordion-button').click(function() {
    $(this).next('.accordion-content').slideToggle(500);
    $(this).parent().find(".accordion-content").not($(this).next()).slideUp(500);
    let othersacbuttons = $(this).parent().find(".accordion-button.open").not(this);
    for (let i = 0; i < othersacbuttons.length; i++) {
      toggleShowHide(othersacbuttons.eq(i));
    }
    $(this).parent().find(".accordion-button").not(this).removeClass('open');
    $(this).toggleClass('open');
    toggleShowHide($(this));
    
    if (!$(this).hasClass('open')) {
      $('html, body').animate(
        {
          scrollTop: $(this).parent().offset().top
        },
        500
      );
    }
  });

// ACCORDION HIDE / SHOW
  $('.accordion-heading').click(function() {

    const $accordion = $(this).parent();
    const $body = $accordion.find('.accordion-body');

    if (!$body.is(':visible'))
      $accordion.addClass('open');

    $body.slideToggle(500, function() {
      if (!$body.is(':visible'))
        $accordion.removeClass('open');
    });
  });

// COMPOSTABILITY FEATURE IMAGE SLIDE
  var slideIndex = 1;
  showSlides(slideIndex);

  $('.feature-slides-next').click(function() {
    showSlides((slideIndex += 1));
  });

  function showSlides(n) {
    var i;
    var slides = $('.feature-slides');
    if (n > slides.length) {
      slideIndex = 1;
    }
    if (1 > n) {
      slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      $(slides[i]).css('display', 'none');
    }

    $(slides[slideIndex - 1]).css('display', 'block');
  }

// COMPOSTABILITY INTRO TEXT HIDE/SHOW

  (function() {
    const introCollapsedContent = $('.intro .collapsed-content');
    const introButtonContainer = $('.intro .button-container');
    const introButton = $('.intro .button');

    introButton.click(function() {
      introCollapsedContent.toggleClass('show');
      introButtonContainer.toggleClass('show');
      toggleShowHide($(this).parent());
    });
  })();

// STRAW INFO POPUP
  $('.info-button').click(function() {
    $('.straw-info-popup').addClass('active');
  });

  $('.straw-info-popup__close').click(function() {
    $('.straw-info-popup').removeClass('active');
  });

// STRAW TIMELINE

  const timeLineImageContainers = $('.mimic-nature__images');
  const strawTimeLineNextButton = $('.mimic-nature__control-mobile .next-button');
  const strawTimeLinePrevButton = $('.mimic-nature__control-mobile .prev-button');

//  show first index as default
  let activeStrawTimelineIndex = 0;
  showTimeLineImage(activeStrawTimelineIndex);

  $('.mimic-nature__control-desktop .button').click(function() {
    showTimeLineImage((activeStrawTimelineIndex = $(this).index()));
  });

  strawTimeLinePrevButton.click(function() {
    if (0 < activeStrawTimelineIndex) {
      activeStrawTimelineIndex--;
      showTimeLineImage(activeStrawTimelineIndex);
    }
  });

  strawTimeLineNextButton.click(function() {
    if (activeStrawTimelineIndex < timeLineImageContainers.length - 1) {
      activeStrawTimelineIndex++;
      showTimeLineImage(activeStrawTimelineIndex);
    }
  });

  function showTimeLineImage(activeIndex) {
    var timeLineDesktopButtons = $('.mimic-nature__control-desktop .button');
    var timeLineMobileButtons = $('.mimic-nature__control-mobile .button');

    // Hide all the images
    for (let i = 0; i < timeLineImageContainers.length; i++) {
      $(timeLineImageContainers[i]).css('display', 'none');
    }

    // Remove active from all desktop buttons
    for (let i = 0; i < timeLineDesktopButtons.length; i++) {
      $(timeLineDesktopButtons[i]).removeClass('active');
    }

    // Hide all mobile buttons
    for (let i = 0; i < timeLineMobileButtons.length; i++) {
      $(timeLineMobileButtons[i]).css('display', 'none');
    }

    // Show prev button only when page is not first one
    if (0 < activeIndex) {
      strawTimeLinePrevButton.css('visibility', 'visible');
    } else {
      strawTimeLinePrevButton.css('visibility', 'hidden');
    }

    // Show next button only when page is not last one
    if (activeIndex < timeLineImageContainers.length - 1) {
      strawTimeLineNextButton.css('visibility', 'visible');
    } else {
      strawTimeLineNextButton.css('visibility', 'hidden');
    }

    $(timeLineImageContainers[activeIndex]).css('display', 'flex');
    $(timeLineMobileButtons[activeIndex]).css('display', 'block');
    $(timeLineDesktopButtons[activeIndex]).addClass('active');
  }

// PRODUCTS COMPARISON BAR SLIDE

  var index = $(
    '.product .comparison-slider .slick-current, .portfolio #other-comparison.comparison-slider .slick-current'
  ).attr('data-slick-index');
  $(
    '.product .comparison-slider .slick-current span.bar.gwp, .portfolio #other-comparison.comparison-slider .slick-current span.bar.gwp'
  ).animate({width: gwpwidth[index] + '%'});
  $(
    '.product .comparison-slider .slick-current span.bar.rrm, .portfolio #other-comparison.comparison-slider .slick-current span.bar.rrm'
  ).animate({width: rrmwidth[index] + '%'});
  $(
    '.product .comparison-slider .slick-current span.bar.bio, .portfolio #other-comparison.comparison-slider .slick-current span.bar.bio'
  ).animate({width: biowidth[index] + '%'});
  $(
    '.product .comparison-slider .slick-current span.bar.mfr, .portfolio #other-comparison.comparison-slider .slick-current span.bar.mfr'
  ).animate({width: mfrwidth[index] + '%'});

  $('.product .comparison-slider, .portfolio #other-comparison.comparison-slider').on('afterChange', function(
    event,
    slick,
    currentSlide,
    nextSlide
  ) {
    var index = $(this).find('.slick-current').attr('data-slick-index');

    $(this)
      .find('.slick-current span.bar.gwp')
      .animate({width: gwpwidth[index] + '%'});
    $(this).find('.slick-slide span.bar.gwp').css('width', '12px').next();
    $(this)
      .find('.slick-current span.bar.rrm')
      .animate({width: rrmwidth[index] + '%'});
    $(this).find('.slick-slide span.bar.rrm').css('width', '12px').next();
    $(this)
      .find('.slick-current span.bar.bio')
      .animate({width: biowidth[index] + '%'});
    $(this).find('.slick-slide span.bar.bio').css('width', '12px').next();
    $(this)
      .find('.slick-current span.bar.mfr')
      .animate({width: mfrwidth[index] + '%'});
    $(this).find('.slick-slide span.bar.mfr').css('width', '12px').next();

    // console.log(modulo);
  });

  var index = $('.portfolio #sulapac-comparison.comparison-slider .slick-current').attr('data-slick-index');

  $('.portfolio #sulapac-comparison.comparison-slider .slick-current span.bar.gwp').animate({
    width: sulapacgwpwidth[index] + '%'
  });
  $('.portfolio #sulapac-comparison.comparison-slider .slick-current span.bar.rrm').animate({
    width: sulapacrrmwidth[index] + '%'
  });
  $('.portfolio #sulapac-comparison.comparison-slider .slick-current span.bar.bio').animate({
    width: sulapacbiowidth[index] + '%'
  });
  $('.portfolio #sulapac-comparison.comparison-slider .slick-current span.bar.mfr').animate({
    width: sulapacmfrwidth[index] + '%'
  });

  $('.portfolio #sulapac-comparison-mobile.comparison-slider .slick-current span.bar.gwp').animate({
    width: sulapacgwpwidth[index] + '%'
  });
  $('.portfolio #sulapac-comparison-mobile.comparison-slider .slick-current span.bar.rrm').animate({
    width: sulapacrrmwidth[index] + '%'
  });
  $('.portfolio #sulapac-comparison-mobile.comparison-slider .slick-current span.bar.bio').animate({
    width: sulapacbiowidth[index] + '%'
  });
  $('.portfolio #sulapac-comparison-mobile.comparison-slider .slick-current span.bar.mfr').animate({
    width: sulapacmfrwidth[index] + '%'
  });

  $('.portfolio #sulapac-comparison.comparison-slider').on('afterChange', function(
    event,
    slick,
    currentSlide,
    nextSlide
  ) {
    var index = $(this).find('.slick-current').attr('data-slick-index');

    $(this)
      .find('.slick-current span.bar.gwp')
      .animate({width: sulapacgwpwidth[index] + '%'});
    $(this).find('.slick-slide span.bar.gwp').css('width', '12px').next();
    $(this)
      .find('.slick-current span.bar.rrm')
      .animate({width: sulapacrrmwidth[index] + '%'});
    $(this).find('.slick-slide span.bar.rrm').css('width', '12px').next();
    $(this)
      .find('.slick-current span.bar.bio')
      .animate({width: sulapacbiowidth[index] + '%'});
    $(this).find('.slick-slide span.bar.bio').css('width', '12px').next();
    $(this)
      .find('.slick-current span.bar.mfr')
      .animate({width: sulapacmfrwidth[index] + '%'});
    $(this).find('.slick-slide span.bar.mfr').css('width', '12px').next();

    // console.log(modulo);
  });

  var singleindex = $('.comparison-slider.single-product .slick-current').attr('data-slick-index');
  $('.comparison-slider.single-product .slick-current span.bar.index:not(.cross):not(.tick)').animate({
    width: indexwidth[singleindex] + '%'
  });
  $('.comparison-slider.single-product .slick-current span.bar.bio:not(.cross):not(.tick)').animate({
    width: biospeedwidth[singleindex] + '%'
  });
  $('.comparison-slider.single-product .slick-current span.bar.marine:not(.cross):not(.tick)').animate({
    width: marinewidth[singleindex] + '%'
  });
  $('.comparison-slider.single-product .slick-current span.bar.dropin:not(.cross):not(.tick)').animate({
    width: dropinwidth[singleindex] + '%'
  });
  $('.comparison-slider.single-product .slick-current span.bar.usability:not(.cross):not(.tick)').animate({
    width: usabwidth[singleindex] + '%'
  });

  $('.comparison-slider.single-product').on('afterChange', function(event, slick, currentSlide, nextSlide) {
    var index = $('.slick-current').attr('data-slick-index');

    $('.slick-current span.bar.index').animate({width: indexwidth[singleindex] + '%'});
    $('.slick-slide span.bar.index').css('width', '12px').next();

    $('.slick-current span.bar.bio').animate({width: biospeedwidth[singleindex] + '%'});
    $('.slick-slide span.bar.bio').css('width', '12px').next();

    $('.slick-current span.bar.marine').animate({width: marinewidth[singleindex] + '%'});
    $('.slick-slide span.bar.marine').css('width', '12px').next();

    $('.slick-current span.bar.dropin').animate({width: dropinwidth[singleindex] + '%'});
    $('.slick-slide span.bar.dropin').css('width', '12px').next();

    $('.slick-current span.bar.usability').animate({width: usabwidth[singleindex] + '%'});
    $('.slick-slide span.bar.usability').css('width', '12px').next();
  });

  // round image item toggler
  $('.round-image-title .item').each(function() {
    const $item = $(this);
    $item.find('.item__image_container').click(function() {
      $item.find('.item__image_container').toggleClass('toggled');
    });
  });

// SET SWIPE INDICATOR HEIGHT

  var imageheight = $('.timeline-slider .image').innerHeight();

  $('.timeline .swipe-indicator-wrapper').css('height', imageheight);

// SMOOTH SCROLL
// Select all links with hashes
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
        location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate(
            {
              scrollTop: target.offset().top
            },
            1000,
            function() {
              // Callback after animation
              // Must change focus!
              var $target = $(target);
              $target.focus();
              if ($target.is(':focus')) {
                // Checking if the target was focused
                return false;
              } else {
                $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                $target.focus(); // Set focus again
              }
            }
          );
        }
      }
    });

// GOOGLE MAP

  function newMap($el) {
    // var
    var $markers = $el.find('.marker');

    // vars
    var args = {
      zoom: 16,
      center: new google.maps.LatLng(0, 0),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    // create map
    var map = new google.maps.Map($el[0], args);

    // add a markers reference
    map.markers = [];

    // add markers
    $markers.each(function() {
      addMarker($(this), map);
    });

    // center map
    centerMap(map);

    // return
    return map;
  }

  function addMarker($marker, map) {
    // var
    var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

    // create marker
    var marker = new google.maps.Marker({
      position: latlng,
      map: map
    });

    // add to array
    map.markers.push(marker);

    // if marker contains HTML, add it to an infoWindow
    if ($marker.html()) {
      // create info window
      var infowindow = new google.maps.InfoWindow({
        content: $marker.html()
      });

      // show info window when marker is clicked
      google.maps.event.addListener(marker, 'click', function() {
        infowindow.open(map, marker);
      });
    }
  }

  function centerMap(map) {
    // vars
    var bounds = new google.maps.LatLngBounds();

    // loop through all markers and create bounds
    $.each(map.markers, function(i, marker) {
      var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());

      bounds.extend(latlng);
    });

    // only 1 marker?
    if (1 == map.markers.length) {
      // set center of map
      map.setCenter(bounds.getCenter());
      map.setZoom(16);
    } else {
      // fit to bounds
      map.fitBounds(bounds);
    }
  }

  var map = null;

  $('.acf-map').each(function() {
      // create map
      map = newMap($(this));
    });
});

