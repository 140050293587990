jQuery(document).ready(function ($) {

  if (0 === $('.for-partners-content').length) {
    return;
  }

  const ga = window.ga;
  const hubspotFormId = '#hubspot-asset-order-form';
  const $form = $('form.order-form');
  const $assetOrderFormModal = $('#asset-order-form');
  const $thankYou = $('.thank-you');

  $('#restricted-area').modal('show');

  $assetOrderFormModal.on('show.bs.modal', function (event) {
    $form.trigger('reset');
    $form.show();
    $thankYou.hide();
  });

  function getFormData($form) {
    const data = {};

    $form.serializeArray().map(function (item) {

      const resolveValue = () => {
        const assetItem = $form.find(`input[name='${item.name}']`).hasClass('asset-item');

        if (assetItem) {
          return item.value > 0 ? `${item.name}=${item.value}` : undefined
        }

        return item.value;
      }

      const value = resolveValue();

      if (value === undefined) {
        return;
      }

      if (data[item.name]) {
        if ('string' === typeof data[item.name]) {
          data[item.name] = [data[item.name]];
        }

        data[item.name].push(value);
      } else {
        data[item.name] = value;
      }
    });

    return data;
  }

  function displayThankYouPage() {

    $('.order-form-container').hide();
    $thankYou.show();

    ga && ga('send', {
      hitType: 'event',
      eventCategory: 'Forms',
      eventAction: 'Form-submit',
      eventLabel: 'Purchace'
    });

    return false;
  }

  function prepareForm() {

    $('select').change(function () {
      $(this).removeClass('placeholder');
    });

    function submitToHubspot(sourceFormData) {
      console.log('Form data:', sourceFormData);

      if (!hubspotMapping) {
        console.warn('No hubspot mapping available!');

        return;
      }

      const {formData} = hubspotMapping;

      if (!formData) {
        console.warn('No hubspot mapping available for form data!');

        return;
      }

      const $targetForm = $(`${hubspotFormId} form`);

      function mapValues(mapping, sourceData, isArray = false) {
        Object.keys(mapping).forEach((key) => {
          const sourceValue = sourceData[key];

          if (!sourceValue) {
            return;
          }

          const targetName = mapping[key];
          const $targetInput = $targetForm.find(`input[name="${targetName}"]`);

          if (0 < $targetInput.length) {
            const isCheckbox = 'checkbox' === $targetInput.attr('type');
            const currentVal = $targetInput.val();
            const nextVal = (currentVal ? `${currentVal}, ` : '') + (isArray ? sourceValue.join(', ') : sourceValue);

            console.log('next value', nextVal);
            isCheckbox ? $targetInput.prop('checked', sourceValue) : $targetInput.val(nextVal);
          } else {
            console.warn(`Hubspot form has no target field of ${targetName}`);
          }
        });
      }

      mapValues(formData, sourceFormData);

      $targetForm.submit();
    }

    function onContactFormSubmit() {
      if ($(this).valid()) {
        const formData = getFormData($(this));

        console.log(formData);

        displayThankYouPage();
        submitToHubspot(formData);
      }

      return false;
    }

    $form.on('submit', onContactFormSubmit);

    function setupValidation() {

      const mergeObjects = (obj1, obj2) => ({...obj1, ...obj2});
      const rules = $form.find('.order-section .items input[type="number"]').map(function() {
        const $input = $(this);
        return {
          [$input.attr('name')]: {
            require_from_group: [1,'.number-group']
          }};
      })
        .get()
        .reduce(mergeObjects, {});

      $form.validate({
        submitHandler: function (form) {
          return false;
        },
        rules
      });

    //add require from group method
      jQuery.validator.addMethod("require_from_group", function(value, element, options) {
        const validator = this;
        const selector = options[1];
        const validOrNot = $(selector, element.form).filter(function() {
          return validator.elementValue(this);
        }).length >= options[0];

        if(!$(element).data('being_validated')) {
          const fields = $(selector, element.form);
          fields.data('being_validated', true);
          fields.valid();
          fields.data('being_validated', false);
        }
        return validOrNot;
      }, jQuery.validator.format("Please fill at least {0} of these fields."));
    }

    setupValidation();

    hbspt.forms.create({
      portalId: '4595283',
      formId: hubspotMapping && hubspotMapping.formId,
      target: hubspotFormId,
      inlineMessage: 'Bogus to keep from redirect',
      onFormSubmitted: function ($form) {
        displayThankYouPage();
      },
    });
  }

  prepareForm();
});
